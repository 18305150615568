<template>
  <div class="card">
    <div class="card-header">
      <h4>Ingredient {{ form.name }}</h4>
      <div class="card-header-form">
        <div class="input-group">
          <button
            class="btn btn-primary"
            @click="toggleModalComponent"
            v-if="!showModalIngredient"
          >
            Add
          </button>
          <button class="btn btn-primary" @click="toggleModalComponent" v-else>
            Product Ingredient
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!showModalIngredient">
        <v-data-table
          :headers="headers"
          :items="product_ingredient"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :page.sync="page"
          v-if="!showModalIngredient"
        >
          <template v-slot:item="row">
            <tr>
              <td>
                {{ row.item.product?.name }}
              </td>
              <td>
                {{ row.item.unit?.name }}
              </td>
              <td>{{ row.item.quantity }}</td>
              <td>
                <button
                  @click="deleteComponent(row.item.id)"
                  class="badge badge-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else>
        <form @submit.prevent="productIngredientInsert">
          <div class="form-group">
            <label>Product</label>
            <model-list-select
              :list="products"
              v-model="field.product_id"
              option-value="id"
              option-text="name"
              placeholder="Select Product"
            >
            </model-list-select>
            <small class="text-danger" v-if="errors.product_id">{{
              errors.product_id[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Qty</label>
            <input
              type="number"
              class="form-control"
              v-model="field.quantity"
            />
            <small class="text-danger" v-if="errors.quantity">{{
              errors.quantity[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Unit</label>
            <model-list-select
              :list="product_units_data"
              v-model="field.unit_id"
              option-value="id"
              option-text="name"
              placeholder="Select Unit"
            >
            </model-list-select>
            <small class="text-danger" v-if="errors.unit_id">{{
              errors.unit_id[0]
            }}</small>
          </div>
          <div class="card-footer text-right">
            <button
              class="btn btn-primary mr-1"
              type="submit"
              :disabled="isSubmitting"
            >
              Submit
            </button>
            <button class="btn btn-secondary" type="reset">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ProfileRepository } from '../../../repositories/RepositoryFactory';
import { apiGet } from '../../../services/api';
import axios from 'axios';
import swal from 'sweetalert';
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      product_ingredient: [],
      showModalIngredient: false,
      field: {
        product_parent_id: parseInt(this.$route.params.id),
        product_id: '',
        quantity: '',
        unit_id: '',
      },
      form: [],
      product_units_data: [],
      products: [],
      errors: {},
      isSubmitting: false,
      venue_id: 1,
    };
  },
  mounted() {
    this.getData();
    this.getProducts();
    this.getUnit();
    ProfileRepository.get().then(({ data }) => {
      if (data.data.venue_id) {
        this.venue_id = data.data.venue_id;
      }
    });
  },
  methods: {
    toggleModalComponent() {
      this.showModalIngredient = !this.showModalIngredient;
      this.getData();
    },
    getData() {
      let id = this.$route.params.id;
      apiGet('product/' + id)
        .then((result) => {
          this.form = result.data.data;
          this.product_ingredient = result.data.data.product_ingredients;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProducts() {
      apiGet('product/all/minimal', {
        venue_id: this.venue_id,
      }).then((res) => {
        this.products = res.data.data;
      });
    },
    getUnit() {
      axios
        .get(this.$store.state.api + 'unit', {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.product_units_data = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    productIngredientInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + 'product_ingredient/store', this.field, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal('Error!', res.data.message, 'error');
          } else {
            this.$noty.success('Your ingredient has been saved!');
            this.showModalIngredient = false;
            this.isSubmitting = false;
            this.getData();
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    deleteComponent(id) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this imaginary file!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + 'product_ingredient/' + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_ingredient = this.product_ingredient.filter(
                  (v) => {
                    return v.id != id;
                  }
                ))
            )
            .catch((err) => {
              console.log(err);
              swal('Error!', err.message, 'error');
            });
          swal('Poof! Your product ingredient file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your product ingredient file is safe!');
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Product',
          value: 'name_product',
        },
        {
          text: 'Unit',
          value: 'name_unit',
        },
        {
          text: 'Qty',
          value: 'qty',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ];
    },
  },
};
</script>
