<template>
  <div class="card">
    <div class="card-header">
      <h4>Stock {{ form.name }}</h4>
    </div>
    <div class="card-body">
      <div v-if="!showModalStock">
        <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-data-table
          :headers="headers"
          :items="stocks"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :page.sync="page"
          v-else
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.venue_name }}</td>
              <td v-for="(unit, index) in row.item.product_units" :key="index">
                {{ unit.stock }}/{{ unit.unit_name }},
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <stock-form v-else @showModalStock="toggleModalStock"></stock-form>
    </div>
  </div>
</template>
<script>
import StockForm from "./Form.vue";
export default {
  components: { StockForm },
  props: ["stocks"],
  data() {
    return {
      page: 1,
      pageCount: 0,
      product_stock: [],
      showModalStock: false,
      form: [],
      loading: false,
    };
  },
  methods: {
    toggleModalStock() {
      this.showModalStock = !this.showModalStock;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Venue",
          value: "venue_name",
        },
        {
          text: "Stock",
        },
      ];
    },
  },
};
</script>
