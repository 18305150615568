<template>
  <div>
    <form @submit.prevent="additionalInsert">
      <div class="form-group">
        <label>Product</label>
        <v2-select
          v-model="field.product_id"
          :options="products"
          :reduce="(products) => products.id"
          @input="getProductUnitParams"
          label="name"
        >
        </v2-select>
        <small class="text-danger" v-if="errors.product_id">{{
          errors.product_id[0]
        }}</small>
      </div>
      <div class="form-group">
        <label>Qty</label>
        <input type="number" class="form-control" v-model="field.qty" />
        <small class="text-danger" v-if="errors.qty">{{ errors.qty[0] }}</small>
      </div>
      <div class="form-group">
        <label>Unit</label>
        <v2-select
          v-model="field.unit_id"
          :options="product_units_data"
          :reduce="(product_units_data) => product_units_data.unit_id"
          label="unit_name"
        >
        </v2-select>
        <small class="text-danger" v-if="errors.unit_id">{{
          errors.unit_id[0]
        }}</small>
      </div>
      <div class="card-footer text-right">
        <button
          class="btn btn-primary mr-1"
          type="submit"
          :disabled="isSubmitting"
        >
          Submit
        </button>
        <button class="btn btn-secondary" type="reset">Reset</button>
      </div>
    </form>
  </div>
</template>

<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      field: {
        parent_product_id: this.$route.params.id,
        product_id: "",
        qty: "",
        unit_id: "",
      },
      products: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getAllProduct();
  },
  methods: {
    getAllProduct() {
      this.loading = true;
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    additionalInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "product_additional/store", this.field, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your Additional has been saved!");
            this.$emit("showModalAdditional", false);
            this.isSubmitting = false;
            this.getData();
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    getProductUnitParams() {
      this.field.unit_id = "";
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.field.product_id,
          },
        })
        .then((result) => {
          this.product_units_data = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
