<template>
  <div class="card">
    <div class="card-header">
      <h4>Additional {{ form.name }}</h4>
      <div class="card-header-form">
        <div class="input-group">
          <button
            class="btn btn-primary"
            @click="toggleModalAdditional"
            v-if="!showModalAdditional"
          >
            Add
          </button>
          <button class="btn btn-primary" @click="toggleModalAdditional" v-else>
            Product Additional
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!showModalAdditional">
        <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-data-table
          :headers="headers"
          :items="product_additional"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :page.sync="page"
          v-else
        >
          <template v-slot:item="row">
            <tr>
              <td>
                <a
                  :href="
                    $router.resolve({
                      name: 'ProductShow',
                      params: { id: row.item.product_id },
                    }).href
                  "
                >
                  {{ row.item.product_name }}
                </a>
              </td>
              <td>{{ row.item.qty }}/{{ row.item.unit_name }}</td>
              <td>
                <button
                  @click="deleteAdditional(row.item.id)"
                  class="badge badge-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <additional-form
        v-else
        @showModalAdditional="toggleModalAdditional"
      ></additional-form>
    </div>
  </div>
</template>
<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
import AdditionalForm from "./Form.vue";
export default {
  components: { AdditionalForm },
  data() {
    return {
      page: 1,
      pageCount: 0,
      product_additional: [],
      showModalAdditional: false,
      form: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    toggleModalAdditional() {
      this.showModalAdditional = !this.showModalAdditional;
      this.getData();
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("product/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.product_additional = result.data.data.additional;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteAdditional(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_additional/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_additional = this.product_additional.filter(
                  (v) => {
                    return v.id != id;
                  }
                ))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product price file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product price file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Product",
          value: "product_id",
        },
        {
          text: "Qty",
          value: "qty",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>
