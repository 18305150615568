<template>
  <div class="card">
    <div class="card-header">
      <h4>Ladies</h4>
      <div class="card-header-form">
        <div class="input-group">
          <!-- <div
            class="btn btn-primary"
            @click="toggleModalLadies"
            v-if="!showModalLadies"
          >
            Add
          </div>
          <div class="btn btn-primary" @click="toggleModalLadies" v-else>
            Ladies
          </div> -->
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!showModalLadies">
        <v-data-table
          :headers="headers"
          :items="ladies"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :page.sync="page"
        >
          <template v-slot:item="row">
            <tr>
              <td>
                {{ row.item.category_ladies }}
              </td>
              <td>
                {{ row.item.ladies_type }}
              </td>
              <td>{{ row.item.qty }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else>
        <form-ladies></form-ladies>
      </div>
    </div>
  </div>
</template>
<script>
import FormLadies from "./Form.vue";
export default {
  props: ["ladies"],
  components: { FormLadies },
  data() {
    return {
      page: 1,
      pageCount: 0,
      showModalLadies: false,
    };
  },
  methods: {
    toggleModalLadies() {
      this.showModalLadies = !this.showModalLadies;
      this.$parent.getData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Category",
          value: "category_ladies",
        },
        {
          text: "Type",
          value: "ladies_type",
        },
        {
          text: "Qty",
          value: "qty",
        },
      ];
    },
  },
};
</script>
