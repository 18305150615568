<template>
  <div>
    <form @submit.prevent="stockInsert">
      <div class="form-group">
        <label>Venue</label>
        <select class="custom-select" v-model="field.venue_id">
          <option selected="" value="">Open this select menu</option>
          <option v-for="venue in venues" :key="venue.id" :value="venue.id">{{
            venue.title
          }}</option>
        </select>
        <small class="text-danger" v-if="errors.venue_id">{{
          errors.venue_id[0]
        }}</small>
      </div>
      <div class="form-group">
        <label>Status</label>
        <select class="custom-select" v-model="field.status">
          <option selected="" value="">Open this select menu</option>
          <option value="IN">IN</option>
          <option value="OUT">OUT</option>
        </select>
        <small class="text-danger" v-if="errors.status">{{
          errors.status[0]
        }}</small>
      </div>
      <div class="form-group">
        <label for="">Stock</label>
        <input type="number" class="form-control" v-model="field.stock" />
        <small class="text-danger" v-if="errors.stock">{{
          errors.stock[0]
        }}</small>
      </div>
      <div class="card-footer text-right">
        <button
          class="btn btn-primary mr-1"
          type="submit"
          :disabled="isSubmitting"
        >
          Submit
        </button>
        <button class="btn btn-secondary" type="reset">Reset</button>
      </div>
    </form>
  </div>
</template>

<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      venues: [],
      errors: [],
      field: {
        venue_id: "",
        product_id: this.$route.params.id,
        stock: "",
        status: "",
      },
      isSubmitting: false,
    };
  },
  mounted() {
    this.getVenues();
  },
  methods: {
    getVenues() {
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    stockInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "product_stock/store", this.field, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your stock has been saved!");
            this.$emit("showModalStock", false);
            this.isSubmitting = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style></style>
