<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="5"
      item-key="id"
      hide-default-footer
      style="white-space: nowrap"
      @page-count="pageCount = $event"
      :page.sync="page"
      v-else
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.name_venue }}</td>
          <td>Rp {{ formatPrice(row.item.price) }}</td>
          <td v-html="parsePriceChange(row.item.change_price)"></td>
          <td>Rp {{ formatPrice(row.item.price_selling) }}</td>
          <td v-html=parsePriceChange(row.item.change_price_selling)></td>
          <td>{{ row.item.name_unit }}</td>
          <td>{{ row.item.buffering }}%</td>
          <td>{{ row.item.created_at }}</td>
          <td>{{ row.item.created_by_name }}</td>
          <td>
            <button
              @click="deletePrice(row.item.id)"
              class="badge badge-danger"
            >
              Delete
            </button>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="card-footer text-right">
      <nav class="d-inline-block">
        <ul class="pagination mb-0">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { apiGet } from "../../../services/api";
export default {
  data() {
    return {
      id: this.$route.params.id,
      data: [],
      page: 1,
      pageCount: 0,
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      apiGet("product_price", {
        product_id: this.id,
      }).then((res) => {
        this.page = res.data.data.current_page;
        this.pageCount = res.data.data.last_page
        this.data = res.data.data.price_per_venue;
      }).finally(() => {
          this.loading = false;
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    parsePriceChange(value) {
        if(value == 0) {
            return value
        }
        let formatter = Intl.NumberFormat('id-Id')
        let val = formatter.format(value)
        if(value > 0) {
            return `<p class="text-success"><i class="fas fa-arrow-up"></i> ${val}</p>`
        } else if (value < 0) {
            return `<p class="text-danger"><i class="fas fa-arrow-down"></i> ${val}</p>`
        } else {
            return '-'
        }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: "Venue",
          value: "venue_id",
        },
        {
          text: "Price",
          value: "price",
        },
        {
          text: "Price Change",
          value: "change_price",
        },
        {
          text: "Selling Price",
          value: "price_selling",
        },
        {
          text: "Selling Price Change",
          value: "change_selling_price",
        },
        {
          text: "Unit",
          value: "name_unit"
        },
        {
          text: "Buffering",
          value: "buffering",
        },
        {
          text: "Created At",
          value: "created_at"
        },
        {
          text: "Created By",
          value: "created_by_name"
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>