<template>
  <div class="card">
    <div class="card-header">
      <h4>Component {{ form.name }}</h4>
      <div class="card-header-form">
        <div class="input-group">
          <button
            class="btn btn-primary"
            @click="toggleModalComponent"
            v-if="!showModalComponent"
          >
            Add
          </button>
          <button class="btn btn-primary" @click="toggleModalComponent" v-else>
            Product Component
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!showModalComponent">
        <v-data-table
          :headers="headers"
          :items="product_component"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :page.sync="page"
          v-if="!showModalComponent"
        >
          <template v-slot:item="row">
            <tr>
              <td>
                <a
                  :href="
                    $router.resolve({
                      name: 'ProductShow',
                      params: { id: row.item.product_id },
                    }).href
                  "
                >
                  {{ row.item.name_product }}
                </a>
              </td>
              <td>{{ row.item.qty }}/{{ row.item.name_unit }}</td>
              <td>
                <button
                  @click="deleteComponent(row.item.id)"
                  class="badge badge-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <div v-else>
        <form @submit.prevent="priceInsert">
          <div class="form-group">
            <label>Product</label>
            <v2-select
              v-model="field.product_id"
              :options="products"
              :reduce="(products) => products.id"
              @input="getProductUnitParams"
              label="name"
            >
            </v2-select>
            <small class="text-danger" v-if="errors.product_id">{{
              errors.product_id[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Qty</label>
            <input type="number" class="form-control" v-model="field.qty" />
            <small class="text-danger" v-if="errors.qty">{{
              errors.qty[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Unit</label>
            <v2-select
              v-model="field.unit_id"
              :options="product_units_data"
              :reduce="(product_units_data) => product_units_data.unit_id"
              label="unit_name"
            >
            </v2-select>
            <small class="text-danger" v-if="errors.unit_id">{{
              errors.unit_id[0]
            }}</small>
          </div>
          <div class="card-footer text-right">
            <button
              class="btn btn-primary mr-1"
              type="submit"
              :disabled="isSubmitting"
            >
              Submit
            </button>
            <button class="btn btn-secondary" type="reset">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      product_component: [],
      showModalComponent: false,
      field: {
        parent_product_id: this.$route.params.id,
        product_id: "",
        qty: "",
        unit_id: "",
      },
      form: [],
      product_units_data: [],
      products: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getData();
    this.getAllProduct();
  },
  methods: {
    toggleModalComponent() {
      this.showModalComponent = !this.showModalComponent;
      this.getData();
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("product/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.product_component = result.data.data.component;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllProduct() {
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams() {
      this.field.unit_id = "";
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.field.product_id,
          },
        })
        .then((result) => {
          this.product_units_data = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    priceInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "product_component/store", this.field, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your unit has been saved!");
            this.showModalComponent = false;
            this.isSubmitting = false;
            this.getData();
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    deleteComponent(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_component/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_component = this.product_component.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product component file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product component file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Product Name",
          value: "name_product",
        },
        {
          text: "Qty",
          value: "qty",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>
