<template>
  <div class="card">
    <div class="card-header">
      <h4>Price {{ form.name }}</h4>
      <div class="card-header-form">
        <div class="input-group">
          <button
            class="btn btn-primary"
            v-if="showModal != 0"
            @click="showModal = 0"
          >
            Product Price
          </button>
          <button
            class="btn btn-primary"
            v-if="showModal != 1"
            @click="showModal = 1"
          >
            Add
          </button>
          <!-- <button
            class="btn btn-primary"
            v-if="showModal != 2"
            @click="showModal = 2"
          >
            History
          </button> -->
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="showModal == 0">
        <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-data-table
          :headers="headers"
          :items="product_price"
          :items-per-page="5"
          item-key="id"
          hide-default-footer
          class="elevation-1"
          style="white-space: nowrap"
          @page-count="pageCount = $event"
          :page.sync="page"
          v-else
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.name_venue }}</td>
              <td>Rp {{ formatPrice(row.item.price) }}</td>
              <td>
                <button
                  @click="deletePrice(row.item.id)"
                  class="badge badge-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="card-footer text-right">
          <nav class="d-inline-block">
            <ul class="pagination mb-0">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <price-form v-if="showModal == 1"></price-form>
      <history v-if="showModal == 2"></history>
    </div>
  </div>
</template>
<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
import PriceForm from "./Form.vue";
import History from "./History.vue";

export default {
  components: {
    PriceForm,
    History,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      product_price: [],
      showModal: 0,
      form: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      let id = this.$route.params.id;
      apiGet("product/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.product_price = result.data.data.price;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletePrice(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_price/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_price = this.product_price.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product price file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product price file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Venue",
          value: "venue_id",
        },
        {
          text: "Selling Price",
          value: "price_selling",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>
