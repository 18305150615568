<template>
  <div>
    <form @submit.prevent="priceInsert">
      <div class="form-group">
        <label>Venue</label>
        <select class="custom-select" v-model="field.venue_id">
          <option selected="" value="">Open this select menu</option>
          <option v-for="venue in venues" :key="venue.id" :value="venue.id">{{
            venue.title
          }}</option>
        </select>
        <small class="text-danger" v-if="errors.venue_id">{{
          errors.venue_id[0]
        }}</small>
      </div>
      <div class="form-group">
        <label>Price</label>
        <money v-model="field.price" class="form-control"></money>
        <small class="text-danger" v-if="errors.price">{{
          errors.price[0]
        }}</small>
      </div>
      <div class="card-footer text-right">
        <button
          class="btn btn-primary mr-1"
          type="submit"
          :disabled="isSubmitting"
        >
          Submit
        </button>
        <button class="btn btn-secondary" type="reset">Reset</button>
      </div>
    </form>
  </div>
</template>

<script>
import { apiGet } from "../../../services/api";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      field: {
        venue_id: "",
        price: "",
        product_id: this.$route.params.id,
        price_selling: "",
        buffering: "",
        unit_id: "",
      },
      venues: [],
      units: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getVenues();
    this.getProductUnitParams();
  },
  methods: {
    getVenues() {
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams() {
      axios
        .get(this.$store.state.api + "product_unit", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.$route.params.id,
          },
        })
        .then((result) => {
          this.units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    priceInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "product_price/store", this.field, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your price has been saved!");
            this.$parent.showModal = 0;
            this.$parent.getData();
            this.isSubmitting = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style></style>
